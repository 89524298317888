.franquicias__container {
    color: #777;
}

.franquicias__infobox {
    border: 1px solid #DDD;
    margin-bottom: .5rem;
}

.franquicias__infobox-main {
    background-color: var(--bg);
}

.franquicias__infobox-boxtitle {
    padding: 1rem;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
}

.franquicias__infobox-innerpanel {
    position: relative;
    max-height: 0;
    overflow: hidden;
    transform: scaleY(1);
    transition: all var(--speed);
}

.innerpanel-active {
    max-height: 100vh;
    transform-origin: top;
    transition: all .2;
}

.franquicias__infobox-innerpanel>p {
    color: #777;
    padding: 1rem;
    font-size: .9rem;
}

.franquicias__description {
    margin-bottom: 3rem;
}

.infobox-arrow {
    width: 20px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(269deg) brightness(103%) contrast(103%);
    transform: rotate(180deg);
    transition: all var(--speed);
}

.arrowActive {
    transform: rotate(0deg);
    transition: all var(--speed);
}

.franquicias__link-franquiciado {
    color: var(--title)
}

.whatsapp-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366; /* Color de fondo del botón de WhatsApp */
    color: white; /* Color del texto */
    padding: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .whatsapp-btn:hover {
    background-color: #0f5c2b; /* Cambio de color de fondo al pasar el cursor */
  }
  
  .whatsapp-icon {
    font-size: 2em; /* Tamaño del ícono de WhatsApp */
  }

  .download-btn {
    position: fixed;
    bottom: 20px;
    right: 100px; /* Ajusta la posición según tu diseño */
    background-color: #007bff;
    color: white;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s;
    right: 160px;
  }
  
  .download-btn:hover {
    background-color: #0056b3; /* Cambio de color de fondo al pasar el cursor */
  }
  
  .whatsapp-icon,
  .download-icon {
    font-size: 1.5em; /* Tamaño de los íconos */
  }

  .whatsapp-btn span,
.download-btn span {
  display: block;
  font-size: 0.8em; /* Tamaño del texto */
  margin-top: 5px; /* Espacio entre el ícono y el texto */
  text-align: center;
}

  .youtube-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Dos columnas, cada una con un ancho mínimo de 300px */
    gap: 20px; /* Espacio entre los videos */
  }
