.materialContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
}

.btnMateriales {
    margin: 1rem 0;
    font-weight: bolder;
    color: var(--text-color-alt);
    padding: .8rem 3rem;
    display: block;
    width: 70%;
    outline: var(--text-color-alt) solid; 
    text-align: center;
    border-radius: .5rem;
}

.btnMateriales:hover {
    background-color: rgba(0, 0, 255, 0.116);
}

.buttons-container{
    display: flex;
    align-items: center;
    justify-content: center; /* Centrar horizontalmente */
    transition: opacity 0.3s ease;
}

.descuentos-button 
    {
    display: inline-block;
    font-size: 1rem;
    padding: .6rem 1.2rem;
    background-color: var(--bg);
    color: var(--text-color);
    border-radius: var(--border-radius);
    margin: .7rem;
    margin-left: 0;
    font-weight: 600;
    border: 0;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn-alt {
    background-color: #FFF;
    color: var(--bg);
  }
  
  .btn:hover {
    color: var(--title-alt);
    cursor: pointer;
    transition: color var(--speed-slow);
  }
  
  .descuentos-button.active {
    color: var(--title-alt);
  }


/* Estilos responsivos para dispositivos móviles */
@media (max-width: 768px) {
  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .descuentos-button {
    margin: 5px 0;
  }
}

.descuentos-container {
    display: grid;
    grid-template-columns: repeat(var(--columns, 3), 1fr); /* 3 columnas con igual ancho */
    gap: 20px; /* Espaciado entre elementos */
    width: 70%; /* Ancho en PC */
    margin: 0 auto; /* Centrar horizontalmente en PC */
    padding: 20px 0; /* Espacio arriba y abajo */
    justify-content: center; /* Centrar horizontalmente */
    transition: opacity 0.3s ease;
    align-items: center;
  
    /* Estilos responsivos para dispositivos móviles */
    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* Una columna en dispositivos móviles */
      width: 90%; /* Ancho en dispositivos móviles */
      margin: 0 auto; /* Centrar horizontalmente en PC */
      justify-content: center; /* Centrar horizontalmente */
      align-items: center;
    }
  }
  
  /* Estilos para las imágenes (puedes personalizarlos según tus necesidades) */
  .descuentos-container img {
    width: 100%;
    height: auto;
    max-width: 100%;
    transition: opacity 0.3s ease;
  
    /* Centrar horizontal y verticalmente en dispositivos móviles */
    @media (max-width: 768px) {
      display: block; /* Asegura que las imágenes ocupen todo el ancho */
      margin: 0 auto; /* Centra horizontalmente */
    }
  }
