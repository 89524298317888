.sectionAboutText {
    line-height: 1.7;
    font-size: 20px;
    color: var(--text-color-alt);
}

.aboutVideo {
    margin: 2rem 0;
}

.sectionPremios{
    background-color: #FFF;
    color: var(--text-color-alt);
}

.sectionPremiosTitle{
    text-align: center;
    margin-bottom: 3rem;
    font-weight: bold;
    font-size: 40px;
}

.premioDesc{
    display: inline;
    margin-left: .5rem;
}

.divPremio{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .3rem;
}

.boxPremio {
    padding: .7rem 2rem;
    margin: .2rem 0 3rem;
}

.boxPremioEscritorio{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: .7rem 2rem;
    margin: .2rem 0;
}

.iconoPlus {
    width: 30px;
    position: relative;
    right: 30px;
    margin-right: -30px;
}

.referenciaImagen{
    width: 43px;
}

.referencias{
    padding: .7rem 2rem;
    margin: .2rem 0
}

.referenciaContainer{
    display: flex;
    justify-content: space-between;
    width: 150px;
    align-items: center;
    margin-right: 1.5rem;
}

.bordesPremios{
    width: 100%;
}

.fondoEscritorio {
    position: relative;
    bottom: 1800px;
    margin-bottom: -1800px;
    z-index: 2;
}