.gridContainer {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.menu {
    background-color: var(--bg);
}

.adminContainer {
    margin: 0 auto;
    width: 70%;
}

.listaMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listaMenu li {
    display: inline-block;
    padding: .6rem 1.2rem;
    background-color: var(--bg-green);
    color: var(--bg);
    font-weight: bold;
    margin: 2rem auto;
    width: 90%;
    border-radius: .5rem;
    text-align: center;
}

.listaMenu li:hover {
    color: #005bc4;
    background-color: #c5f34f ;
    cursor: pointer;
    transition: .2s all;
}

.switch-container {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch-container input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }