.tyc__container {
    line-height: 1.6rem;
    color: #777;
}

.tyc__container>p {
    margin-bottom: 3rem;
}

.tyc__container>ul {
    padding: 0 1rem;
}

.tyc__container>ul>li {
    list-style-type: disc;
    margin-top: 1rem;
}

.tyc__container ol {
    counter-reset: item;
}

.tyc__container li {
    display: block;
}

.tyc__container li:before {
    content: counters(item, ".") " ";
    font-weight:bold;
    counter-increment: item;
}

.tyc__list>li,
.tyc__list>li>ol>li
{
    margin-bottom: 2rem;
}

.tyc__list>li>ol{
    margin-top: 2rem;
}

.tyc__list p{
    margin: 1rem;
}